import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealHugs', 'textRevealBrag', 'textRevealHighFive', 'textRevealPat', 'textRevealSqueeze', 'textRevealRuffle', 'textRevealInvite', 'textRevealDrape'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "things-you-can-do"
    }}>{`Things you can do`}</h1>
    <p>{`(click text below to reveal)`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} md={3} mdxType="Grid">
    <TextReveal id="textRevealHugs" header="Hugs" mdxType="TextReveal">
      <p>Give them a hug (perhaps when their friends aren't around)!</p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mdxType="Grid">
    <TextReveal id="textRevealBrag" header="Brag" mdxType="TextReveal">
      <p>Let your teenager overhear you bragging about them to your partner, friend or relative.</p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mdxType="Grid">
    <TextReveal id="textRevealHighFive" header="5's" mdxType="TextReveal">
      <p>Give them a high five!</p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mdxType="Grid">
    <TextReveal id="textRevealPat" header="Pat" mdxType="TextReveal">
      <p>Pat them on the back for a job well done!</p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mdxType="Grid">
    <TextReveal id="textRevealSqueeze" header="Squeeze" mdxType="TextReveal">
      <p>Give them a gentle squeeze to show affection.</p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mdxType="Grid">
    <TextReveal id="textRevealRuffle" header="Ruffle" mdxType="TextReveal">
      <p>Ruffling their hair lightly can show affection too!</p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mdxType="Grid">
    <TextReveal id="textRevealInvite" header="Invite" mdxType="TextReveal">
      <p>Invite them along on your walk or errands. They may never say yes but it's the offering that counts.</p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} md={3} mdxType="Grid">
    <TextReveal id="textRevealDrape" header="Drape" mdxType="TextReveal">
      <p>Drape an arm over their shoulder.</p>
    </TextReveal>
  </Grid>
    </Grid>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m1/Oriental_Mo_Son_park.svg" alt="Mother and son in affectionate embrace at a park" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      